dialog {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-weight: normal;
    padding: 0.3em;
}
dialog::backdrop {
    background: none;
}

.metaDialog {
    overflow: hidden;
    border-style: none !important;
    background-color: none !important;
    z-index: 99;
    position: absolute;
    top: 200px !important;
    left: 74% !important;
    right: 1%;
    width: 25%;
}

.header {
    background-color: #26a69a;
    color:#fff;
    text-align: center;
    }

.main_body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: aliceblue;
    font-size: 0.75rem;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.row {
    overflow: hidden;
    border-bottom: 0.5px solid gray;
    width: 100%;
    margin:0.05rem;
}
.column_small {
    float: left;
    text-align: center;
    width: 30%;
    word-wrap: break-word;
}

.column_large {
    float: left;
    text-align: center;
    width: 70%;
    word-wrap: break-word;
}
.main_title {
    width: 100%;
    text-align: center;
    background-color: #26a69a;
    min-height: 2rem;
    display: table !important;
    word-break: break-all !important;
    color:white;
    height: 36px;
    line-height: 36px;
    padding: 0 8px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.title{
    font-size:1rem;
    background-color:aquamarine;
}
.main_info {
    height: 2rem;
    background-color: green;
}
.closeBtn {
    display: inline-block !important;
    left: 80%;
    align-items: space-between !important;
    margin: 0.1rem;
    transform: scale(0.7);
}

#toolLine {
    grid-column-start: 12;
    grid-column-end: 12;
    grid-row-start: 10;
    grid-row-end: 11;
    z-index: 3;
    background-color:white;
    margin-left:5rem;
    margin-right:2rem;
    opacity:0.8;
}
.toolBtns {
    margin: 0.2rem;
    color: #26a69a !important;
}

/* Set the style of the pop up window */
.ol-popup {
        position: absolute;
        background-color: white !important;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
      }
      .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
      }
      .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
      }
      .ol-popup-closer:after {
        content: "✖";
      }


.vertical-menu {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 6;
  background-color:white;
}

.vertical-menu a {
  background-color: #eee; /* Grey background color */
  color: black; /* Black text color */
  display: block; /* Make the links appear below each other */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove underline from links */
}

.vertical-menu a:hover {
  background-color: #ccc; /* Dark grey background on mouse-over */
}

.vertical-menu a.active {
  background-color: #4CAF50; /* Add a green color to the "active/current" link */
  color: white;
}
